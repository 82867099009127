//
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

import Iconify from 'components/iconify';
import Scrollbar from 'components/scrollbar';

import { ProgressContext } from './progress-context';

// ----------------------------------------------------------------------

const ProgressContainer = styled(Stack)(({ theme }) => ({
    position: 'fixed',
    bottom: 0,
    right: 16,
    width: '100%',
    zIndex: 100,
    backgroundColor: theme.palette.background.default,
    borderTopLeftRadius: theme.shape.borderRadius * 2,
    borderTopRightRadius: theme.shape.borderRadius * 2,
    boxShadow:
        '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12),0 1px 2px 0 rgba(60,64,67,.3),0 1px 3px 1px rgba(60,64,67,.15)',
    [theme.breakpoints.down('sm')]: {
        left: 16,
        width: 'calc(100% - 32px)'
    }
}));

const ProgressBar = ({ value = 0 }: { value: number | undefined }) => (
    <Stack>
        <CircularProgress
            variant="determinate"
            size={16}
            value={value ?? 0}
            sx={{
                position: 'absolute',
                zIndex: 1
            }}
        />
        <CircularProgress
            variant="determinate"
            size={16}
            value={100}
            sx={{
                color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 400 : 700]
            }}
        />
    </Stack>
);

// ----------------------------------------------------------------------

export function ProgressConsumer() {
    return (
        <ProgressContext.Consumer>
            {(state) => {
                if (state.open) {
                    return (
                        <ProgressContainer sx={{ maxWidth: { xs: 'unset', sm: 350 } }}>
                            <Stack
                                direction="row"
                                minHeight={54}
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{
                                    paddingLeft: 3,
                                    paddingRight: 2,
                                    backgroundImage: 'url(/assets/cyan-blur.png),url(/assets/red-blur.png)',
                                    backgroundRepeat: 'no-repeat, no-repeat',
                                    backgroundPosition: 'top right, left bottom',
                                    backgroundSize: '50%, 50%'
                                }}
                            >
                                <Typography>
                                    {(() => {
                                        const uploading = state.progress.filter(
                                            (progress) => progress.status === 'processing'
                                        );
                                        const failed = state.progress.filter(
                                            (progress) => progress.status === 'failed'
                                        );
                                        const cancelled = state.progress.filter(
                                            (progress) => progress.status === 'cancelled'
                                        );
                                        const completed = state.progress.filter(
                                            (progress) => progress.status === 'completed'
                                        );
                                        if (uploading.length) return `Uploading ${uploading.length} item`;
                                        if (failed.length) return `${failed.length} upload failed`;
                                        if (cancelled.length) return `${cancelled.length} upload cancelled`;
                                        if (completed.length) return `${completed.length} upload complete`;
                                        return 'No progress';
                                    })()}
                                </Typography>
                                <Stack direction="row" gap={0.25}>
                                    <IconButton size="small" onClick={state.toggleCollapse}>
                                        {state.collapse ? (
                                            <Iconify icon="eva:arrow-ios-downward-fill" />
                                        ) : (
                                            <Iconify icon="eva:arrow-ios-upward-fill" />
                                        )}
                                    </IconButton>
                                    <IconButton size="small" onClick={state.close}>
                                        <Iconify icon="eva:close-fill" />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            <Scrollbar sx={{ maxHeight: 175 }}>
                                <Collapse in={state.collapse} timeout="auto" unmountOnExit>
                                    <List
                                        disablePadding
                                        sx={{
                                            '& .MuiListItemButton-root': {
                                                paddingLeft: 2.5
                                            }
                                        }}
                                    >
                                        {state.progress.map((progress) => (
                                            <ListItemButton key={progress.id} divider>
                                                <ListItemIcon>
                                                    {(() => {
                                                        switch (progress.status) {
                                                            case 'processing': {
                                                                return <ProgressBar value={progress.percent} />;
                                                            }
                                                            case 'failed': {
                                                                return (
                                                                    <Iconify
                                                                        sx={{
                                                                            color: (theme) => theme.palette.error.main
                                                                        }}
                                                                        icon="solar:shield-warning-bold-duotone"
                                                                    />
                                                                );
                                                            }
                                                            case 'cancel': {
                                                                return (
                                                                    <Iconify
                                                                        sx={{
                                                                            color: (theme) => theme.palette.error.main
                                                                        }}
                                                                        icon="solar:close-circle-bold-duotone"
                                                                    />
                                                                );
                                                            }
                                                            case 'completed': {
                                                                return (
                                                                    <Iconify
                                                                        sx={{
                                                                            color: (theme) => theme.palette.success.main
                                                                        }}
                                                                        icon="solar:check-circle-bold-duotone"
                                                                    />
                                                                );
                                                            }
                                                            default: {
                                                                return (
                                                                    <Iconify icon="solar:question-circle-bold-duotone" />
                                                                );
                                                            }
                                                        }
                                                    })()}
                                                </ListItemIcon>
                                                <ListItemText primary={progress.name} />
                                                <IconButton
                                                    size="small"
                                                    onClick={() => state.removeProgress(progress.id)}
                                                >
                                                    <Iconify icon="eva:close-fill" />
                                                </IconButton>
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </Scrollbar>
                        </ProgressContainer>
                    );
                }
                return null;
            }}
        </ProgressContext.Consumer>
    );
}
