import { useReducer, useMemo, useRef, useEffect } from 'react';
// utils
// import axios, { endpoints } from 'utils/axios';
//

import { MAPBOX_API } from 'config-global';

import Geocoding from '@mapbox/mapbox-sdk/services/geocoding';

import { AppContext } from './app-context';

import { ActionMapType, AppLocationType, AppStateType } from '../types';

// ----------------------------------------------------------------------

const geocodingClient = Geocoding({ accessToken: MAPBOX_API });

// ----------------------------------------------------------------------

enum Types {
    UPDATE_LOCATION = 'UPDATE_LOCATION',
    UPDATE_LOCATION_TAG = 'UPDATE_LOCATION_TAG'
}

type Payload = {
    [Types.UPDATE_LOCATION]: {
        location: AppLocationType;
    };
    [Types.UPDATE_LOCATION_TAG]: {
        locationTag: string;
    };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AppStateType = {
    isOnline: navigator ? navigator.onLine : false,
    location: {
        error: null,
        longitude: null,
        latitude: null
    },
    locationTag: ''
};

const reducer = (state: AppStateType, action: ActionsType) => {
    if (action.type === Types.UPDATE_LOCATION) {
        return {
            ...state,
            location: action.payload.location
        };
    }
    if (action.type === Types.UPDATE_LOCATION_TAG) {
        return {
            ...state,
            locationTag: action.payload.locationTag
        };
    }
    return state;
};

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export function AppProvider({ children }: Props) {
    const watchId = useRef<any>(null);

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        if (navigator.geolocation) {
            watchId.current = navigator.geolocation.watchPosition(
                (position) => {
                    dispatch({
                        type: Types.UPDATE_LOCATION,
                        payload: {
                            location: {
                                error: null,
                                longitude: position.coords.longitude,
                                latitude: position.coords.latitude
                            }
                        }
                    });
                },
                (e: any) => {
                    dispatch({
                        type: Types.UPDATE_LOCATION,
                        payload: {
                            location: {
                                error: `Geolocation error: ${e.message}`,
                                longitude: null,
                                latitude: null
                            }
                        }
                    });
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
        return () => {
            if (navigator.geolocation) {
                navigator.geolocation.clearWatch(watchId.current);
            }
        };
    }, []);

    useEffect(() => {
        (async () => {
            if (!state.location.latitude || !state.location.longitude) return;
            const response = await geocodingClient
                .reverseGeocode({
                    query: [state.location.longitude, state.location.latitude],
                    limit: 1,
                    types: ['poi', 'place']
                })
                .send();

            const match = response.body.features[0];

            if (match) {
                dispatch({
                    type: Types.UPDATE_LOCATION_TAG,
                    payload: {
                        locationTag: match.text
                    }
                });
            }
        })();
    }, [state.location.latitude, state.location.longitude]);

    // ----------------------------------------------------------------------

    const memoizedValue = useMemo(
        () => ({
            isOnline: state.isOnline,
            location: state.location,
            locationTag: state.locationTag
        }),
        [state.isOnline, state.location, state.locationTag]
    );

    return <AppContext.Provider value={memoizedValue}>{children}</AppContext.Provider>;
}
