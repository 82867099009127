import { useReducer, useMemo, useEffect, useCallback } from 'react';
// utils
// import axios, { endpoints } from 'utils/axios';
//
import { useLocation, useNavigate } from 'react-router';

import { NavigationContext } from './navigation-context';
import { ActionMapType, NavigationStateType } from '../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
    ADD_HISTORY = 'ADD_HISTORY'
}

type Payload = {
    [Types.ADD_HISTORY]: string;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: NavigationStateType = {
    history: []
};

const reducer = (state: NavigationStateType, action: ActionsType) => {
    if (action.type === Types.ADD_HISTORY) {
        return {
            ...state,
            history: [...state.history, action.payload]
        };
    }
    return state;
};

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export function NavigationProvider({ children }: Props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({
            type: Types.ADD_HISTORY,
            payload: location.pathname
        });
    }, [location.pathname]);

    const back = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const forward = useCallback(() => {
        navigate(1);
    }, [navigate]);

    const refresh = useCallback(() => {
        navigate(0);
    }, [navigate]);

    const home = useCallback(() => {
        navigate('/');
    }, [navigate]);

    // ----------------------------------------------------------------------

    const memoizedValue = useMemo(
        () => ({
            history: state.history,
            back,
            forward,
            refresh,
            home
        }),
        [state.history, back, forward, refresh, home]
    );

    return <NavigationContext.Provider value={memoizedValue}>{children}</NavigationContext.Provider>;
}
