import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// auth
import { AuthGuard, RoleBasedGuard } from 'auth/guard';
// layouts
import DashboardLayout from 'layouts/dashboard';
// components
import { LoadingScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------
// OVERVIEW
const OverviewEcommercePage = lazy(() => import('pages/dashboard/sale'));
const OverviewBankingPage = lazy(() => import('pages/dashboard/overview'));
// USER
const UserListPage = lazy(() => import('pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('pages/dashboard/user/edit'));
const UserViewPage = lazy(() => import('pages/dashboard/user/view'));
// CUSTOMER
const CustomerListPage = lazy(() => import('pages/dashboard/customer/list'));
const CustomerCreatePage = lazy(() => import('pages/dashboard/customer/new'));
const CustomerEditPage = lazy(() => import('pages/dashboard/customer/edit'));
const CustomerViewPage = lazy(() => import('pages/dashboard/customer/view'));
// CONTRACTOR
const SubcontractorListPage = lazy(() => import('pages/dashboard/subcontractor/list'));
const SubcontractorCreatePage = lazy(() => import('pages/dashboard/subcontractor/new'));
const SubcontractorEditPage = lazy(() => import('pages/dashboard/subcontractor/edit'));
const SubcontractorViewPage = lazy(() => import('pages/dashboard/subcontractor/view'));
// INSURANCE AGENT
const InsuranceListPage = lazy(() => import('pages/dashboard/insurance/list'));
const InsuranceCreatePage = lazy(() => import('pages/dashboard/insurance/new'));
const InsuranceEditPage = lazy(() => import('pages/dashboard/insurance/edit'));
// REAL ESTATE
const RealEstateListPage = lazy(() => import('pages/dashboard/real-estate/list'));
const RealEstateCreatePage = lazy(() => import('pages/dashboard/real-estate/new'));
const RealEstateEditPage = lazy(() => import('pages/dashboard/real-estate/edit'));
// INVOICE
const InvoiceListPage = lazy(() => import('pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('pages/dashboard/invoice/edit'));
// ESTIMATE
const EstimateListPage = lazy(() => import('pages/dashboard/estimate/list'));
const EstimateDetailsPage = lazy(() => import('pages/dashboard/estimate/details'));
const EstimateCreatePage = lazy(() => import('pages/dashboard/estimate/new'));
const EstimateEditPage = lazy(() => import('pages/dashboard/estimate/edit'));
// PAYMENT
const AccountingListPage = lazy(() => import('pages/dashboard/accounting/list'));
const AccountingDetailsPage = lazy(() => import('pages/dashboard/accounting/details'));
const AccountingCreatePage = lazy(() => import('pages/dashboard/accounting/new'));
const AccountingEditPage = lazy(() => import('pages/dashboard/accounting/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('pages/dashboard/file-manager'));
// TODO
const TodoPage = lazy(() => import('pages/dashboard/todo-manager'));
// PROJECT
const ProjectDetailsPage = lazy(() => import('pages/dashboard/project/details'));
const ProjectListPage = lazy(() => import('pages/dashboard/project/list'));
const ProjectCreatePage = lazy(() => import('pages/dashboard/project/new'));
const ProjectEditPage = lazy(() => import('pages/dashboard/project/edit'));
// JOB
const JobDetailsPage = lazy(() => import('pages/dashboard/job/details'));
const JobListPage = lazy(() => import('pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('pages/dashboard/job/edit'));
// REPORT
const ReportListPage = lazy(() => import('pages/dashboard/report/list'));
const ReportDetailsPage = lazy(() => import('pages/dashboard/report/details'));
const ReportEditPage = lazy(() => import('pages/dashboard/report/edit'));
// PHOTOS
const PhotoPage = lazy(() => import('pages/dashboard/photo'));
// APP
const ChatPage = lazy(() => import('pages/dashboard/chat'));
const MapPage = lazy(() => import('pages/dashboard/map'));
const SettingPage = lazy(() => import('pages/dashboard/setting'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
    {
        path: 'dashboard',
        element: (
            <AuthGuard>
                <DashboardLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </DashboardLayout>
            </AuthGuard>
        ),
        children: [
            { index: true, element: <Navigate to="app" /> },
            { path: 'app', element: <OverviewBankingPage /> },
            { path: 'sale', element: <OverviewEcommercePage /> },
            {
                path: 'user',
                children: [
                    { element: <Navigate to="list" />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="user">
                                <UserListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="user" destinationKey="create">
                                <UserCreatePage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="user" destinationKey="edit">
                                <UserEditPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/view',
                        element: (
                            <RoleBasedGuard categoryKey="user">
                                <UserViewPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'customer',
                children: [
                    { element: <Navigate to="list" />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="customer">
                                <CustomerListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="customer" destinationKey="create">
                                <CustomerCreatePage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="customer" destinationKey="edit">
                                <CustomerEditPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/view',
                        element: (
                            <RoleBasedGuard categoryKey="customer">
                                <CustomerViewPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'subcontractor',
                children: [
                    { element: <Navigate to="list" />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="subcontractor">
                                <SubcontractorListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="subcontractor" destinationKey="create">
                                <SubcontractorCreatePage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',

                        element: (
                            <RoleBasedGuard categoryKey="subcontractor" destinationKey="edit">
                                <SubcontractorEditPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/view',
                        element: (
                            <RoleBasedGuard categoryKey="subcontractor">
                                <SubcontractorViewPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'insurance',
                children: [
                    { element: <Navigate to="list" />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="insurance">
                                <InsuranceListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="insurance" destinationKey="create">
                                <InsuranceCreatePage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="insurance" destinationKey="edit">
                                <InsuranceEditPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'real-estate',
                children: [
                    { element: <Navigate to="list" />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="realEstate">
                                <RealEstateListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="realEstate" destinationKey="create">
                                <RealEstateCreatePage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="realEstate" destinationKey="edit">
                                <RealEstateEditPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'invoice',
                children: [
                    { element: <InvoiceListPage />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="invoice">
                                <InvoiceListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (
                            <RoleBasedGuard categoryKey="invoice">
                                <InvoiceDetailsPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="invoice" destinationKey="edit">
                                <InvoiceEditPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="invoice" destinationKey="create">
                                <InvoiceCreatePage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'estimate',
                children: [
                    { element: <EstimateListPage />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="estimate">
                                <EstimateListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (
                            <RoleBasedGuard categoryKey="estimate">
                                <EstimateDetailsPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="estimate" destinationKey="edit">
                                <EstimateEditPage />
                            </RoleBasedGuard>
                        )
                    },

                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="estimate" destinationKey="create">
                                <EstimateCreatePage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'accounting',
                children: [
                    { element: <AccountingListPage />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="accounting">
                                <AccountingListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (
                            <RoleBasedGuard categoryKey="accounting">
                                <AccountingDetailsPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="accounting" destinationKey="edit">
                                <AccountingEditPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="accounting" destinationKey="create">
                                <AccountingCreatePage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'project',
                children: [
                    { element: <ProjectListPage />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="project">
                                <ProjectListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (
                            <RoleBasedGuard categoryKey="project">
                                <ProjectDetailsPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="project" destinationKey="create">
                                <ProjectCreatePage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="project" destinationKey="edit">
                                <ProjectEditPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'job',
                children: [
                    { element: <JobListPage />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="job">
                                <JobListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (
                            <RoleBasedGuard categoryKey="job">
                                <JobDetailsPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: 'new',
                        element: (
                            <RoleBasedGuard categoryKey="job" destinationKey="create">
                                <JobCreatePage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="job" destinationKey="edit">
                                <JobEditPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'report',
                children: [
                    { element: <ReportListPage />, index: true },
                    {
                        path: 'list',
                        element: (
                            <RoleBasedGuard categoryKey="report">
                                <ReportListPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id',
                        element: (
                            <RoleBasedGuard categoryKey="report">
                                <ReportDetailsPage />
                            </RoleBasedGuard>
                        )
                    },
                    {
                        path: ':id/edit',
                        element: (
                            <RoleBasedGuard categoryKey="report" destinationKey="edit">
                                <ReportEditPage />
                            </RoleBasedGuard>
                        )
                    }
                ]
            },
            {
                path: 'file-manager',
                element: (
                    <RoleBasedGuard categoryKey="fileManager">
                        <FileManagerPage />
                    </RoleBasedGuard>
                )
            },

            {
                path: 'todo',
                element: (
                    <RoleBasedGuard categoryKey="todo">
                        <TodoPage />
                    </RoleBasedGuard>
                )
            },
            {
                path: 'chat',
                element: (
                    <RoleBasedGuard categoryKey="communication">
                        <ChatPage />
                    </RoleBasedGuard>
                )
            },

            {
                path: 'map',
                element: (
                    <RoleBasedGuard categoryKey="map">
                        <MapPage />
                    </RoleBasedGuard>
                )
            },
            {
                path: 'photo',
                element: (
                    <RoleBasedGuard categoryKey="photo">
                        <PhotoPage />
                    </RoleBasedGuard>
                )
            },
            { path: 'setting', element: <SettingPage /> }
        ]
    }
];
