import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { GuestGuard } from 'auth/guard';
// layouts
import AuthClassicLayout from 'layouts/auth/classic';
// components
import { SplashScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const LoginPage = lazy(() => import('pages/auth/login'));
const RegisterPage = lazy(() => import('pages/auth/register'));
const OPTPage = lazy(() => import('pages/auth/opt'));

// ----------------------------------------------------------------------

export const authRoutes = [
    {
        path: 'auth',
        element: (
            <GuestGuard>
                <Suspense fallback={<SplashScreen />}>
                    <Outlet />
                </Suspense>
            </GuestGuard>
        ),
        children: [
            {
                path: 'login',
                element: (
                    <AuthClassicLayout>
                        <LoginPage />
                    </AuthClassicLayout>
                )
            },
            {
                path: 'register',
                element: (
                    <AuthClassicLayout>
                        <RegisterPage />
                    </AuthClassicLayout>
                )
            },
            {
                path: 'opt',
                element: (
                    <AuthClassicLayout>
                        <OPTPage />
                    </AuthClassicLayout>
                )
            }
        ]
    }
];
