import useSWR, { mutate } from 'swr';

import { useMemo } from 'react';

// utils
import axios, { fetcher, endpoints } from 'utils/axios';

import { INotification } from 'types/notifications';

// ----------------------------------------------------------------------

const URL = endpoints.notifications;
export function useGetNotifications() {
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
            notifications: (data as INotification[]) ?? [],
            notificationsLoading: isLoading,
            notificationsError: error,
            notificationsValidating: isValidating,
            notificationsEmpty: !isLoading && !(data ?? []).length
        }),
        [data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// ----------------------------------------------------------------------

export async function realAll() {
    /**
     * Work on server
     */
    await axios.post(`${URL}/read-all`);

    /**
     * Work in local
     */
    mutate(
        URL,
        (currentData: any) => currentData.map((notification: INotification) => ({ ...notification, status: 1 })),
        false
    );
}

// ----------------------------------------------------------------------

export async function updateNotification(notificationId: string, notificationData: any) {
    /**
     * Work on server
     */
    const data = notificationData;
    const response = await axios.put(`${URL}/${notificationId}`, data);

    /**
     * Work in local
     */
    mutate(
        URL,
        (currentData: any) =>
            currentData.map((notification: INotification) => {
                if (notification.id === notificationId) return response.data.notification;
                return notification;
            }),
        false
    );
}
