import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import PublicLayout from 'layouts/public';

// ----------------------------------------------------------------------

const Invoice = lazy(() => import('pages/view/invoice/details'));
const Estimate = lazy(() => import('pages/view/estimate/details'));
const Report = lazy(() => import('pages/view/report/details'));
const Subcontractor = lazy(() => import('pages/view/subcontractor/details'));
const Job = lazy(() => import('pages/view/job/details'));

// ----------------------------------------------------------------------

export const publicRoutes = [
    {
        element: (
            <PublicLayout>
                <Outlet />
            </PublicLayout>
        ),
        children: [
            { path: 'invoice/:invoiceId/:customerId', element: <Invoice /> },
            { path: 'estimate/:estimateId/:customerId', element: <Estimate /> },
            { path: 'report/:reportId', element: <Report /> },
            { path: 'subcontractor/:subcontractorId', element: <Subcontractor /> },
            { path: 'job/:jobId/subcontractor/:subcontractorId', element: <Job /> }
        ]
    }
];
