import { useReducer, useCallback, useMemo } from 'react';
// utils
// import axios, { endpoints } from 'utils/axios';
//
import { ProgressContext } from './progress-context';
import { ActionMapType, ProgressStateType, ProgressType } from '../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
    ADD_PROGRESS = 'ADD_PROGRESS',
    REMOVE_PROGRESS = 'REMOVE_PROGRESS',
    CANCEL_PROGRESS = 'CANCEL_PROGRESS',
    COMPLETED_PROGRESS = 'COMPLETED_PROGRESS',
    FAILED_PROGRESS = 'FAILED_PROGRESS',
    TOGGLE_COLLAPSE = 'TOGGLE_COLLAPSE',
    UPDATE_PROGRESS = 'UPDATE_PROGRESS',
    CLOSE = 'CLOSE'
}

type Payload = {
    [Types.ADD_PROGRESS]: ProgressType;
    [Types.UPDATE_PROGRESS]: ProgressType;
    [Types.REMOVE_PROGRESS]: {
        id: string;
    };
    [Types.CANCEL_PROGRESS]: {
        id: string;
    };
    [Types.COMPLETED_PROGRESS]: {
        id: string;
    };
    [Types.FAILED_PROGRESS]: {
        id: string;
    };
    [Types.TOGGLE_COLLAPSE]: null;
    [Types.CLOSE]: null;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: ProgressStateType = {
    progress: [],
    open: false,
    collapse: true
};

const reducer = (state: ProgressStateType, action: ActionsType) => {
    if (action.type === Types.ADD_PROGRESS) {
        return {
            ...state,
            open: true,
            progress: [...state.progress, action.payload]
        };
    }
    if (action.type === Types.TOGGLE_COLLAPSE) {
        return {
            ...state,
            collapse: !state.collapse
        };
    }
    if (action.type === Types.COMPLETED_PROGRESS) {
        return {
            ...state,
            progress: state.progress.map((progress) => {
                if (progress.id === action.payload.id) {
                    return {
                        ...progress,
                        status: 'completed',
                        percent: 100
                    };
                }
                return progress;
            })
        };
    }
    if (action.type === Types.FAILED_PROGRESS) {
        return {
            ...state,
            progress: state.progress.map((progress) => {
                if (progress.id === action.payload.id) {
                    return {
                        ...progress,
                        status: 'failed',
                        percent: 100
                    };
                }
                return progress;
            })
        };
    }
    if (action.type === Types.CANCEL_PROGRESS) {
        return {
            ...state,
            progress: state.progress.map((progress) => {
                if (progress.id === action.payload.id) {
                    return {
                        ...progress,
                        status: 'cancelled',
                        percent: 100
                    };
                }
                return progress;
            })
        };
    }
    if (action.type === Types.UPDATE_PROGRESS) {
        return {
            ...state,
            progress: state.progress.map((progress) => {
                if (progress.id === action.payload.id) {
                    return action.payload;
                }
                return progress;
            })
        };
    }
    if (action.type === Types.REMOVE_PROGRESS) {
        return {
            ...state,
            progress: state.progress.filter((progress) => progress.id !== action.payload.id)
        };
    }
    if (action.type === Types.CLOSE) {
        return {
            ...state,
            open: false,
            progress: []
        };
    }
    return state;
};

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export function ProgressProvider({ children }: Props) {
    const [state, dispatch] = useReducer(reducer, initialState);

    // ADD PROGRESS
    const addProgress = useCallback((id: string, name: string) => {
        dispatch({
            type: Types.ADD_PROGRESS,
            payload: {
                id,
                status: 'processing',
                percent: 0,
                name
            }
        });
    }, []);

    // TOGGLE COLLAPSE
    const toggleCollapse = useCallback(() => {
        dispatch({
            type: Types.TOGGLE_COLLAPSE,
            payload: null
        });
    }, []);

    // COMPLETE PROGRESS
    const completeProgress = useCallback((id: string) => {
        dispatch({
            type: Types.COMPLETED_PROGRESS,
            payload: { id }
        });
    }, []);

    // FAILED PROGRESS
    const failedProgress = useCallback((id: string) => {
        dispatch({
            type: Types.FAILED_PROGRESS,
            payload: { id }
        });
    }, []);

    // CANCEL PROGRESS
    const cancelProgress = useCallback((id: string) => {
        dispatch({
            type: Types.CANCEL_PROGRESS,
            payload: { id }
        });
    }, []);

    // REMOVE PROGRESS
    const removeProgress = useCallback((id: string) => {
        dispatch({
            type: Types.REMOVE_PROGRESS,
            payload: { id }
        });
    }, []);

    // CLOSE
    const close = useCallback(() => {
        dispatch({
            type: Types.CLOSE,
            payload: null
        });
    }, []);

    // UPDATE PROGRESS
    const updateProgress = useCallback((id: string, status: string, percent: number, name: string) => {
        dispatch({
            type: Types.UPDATE_PROGRESS,
            payload: {
                id,
                status,
                percent,
                name
            }
        });
    }, []);

    // ----------------------------------------------------------------------

    const memoizedValue = useMemo(
        () => ({
            open: state.open,
            progress: state.progress,
            collapse: state.collapse,
            //
            addProgress,
            toggleCollapse,
            updateProgress,
            cancelProgress,
            failedProgress,
            completeProgress,
            removeProgress,
            close
        }),
        [
            state.open,
            state.progress,
            state.collapse,
            //
            addProgress,
            toggleCollapse,
            updateProgress,
            cancelProgress,
            failedProgress,
            completeProgress,
            removeProgress,
            close
        ]
    );

    return <ProgressContext.Provider value={memoizedValue}>{children}</ProgressContext.Provider>;
}
