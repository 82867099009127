// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
//
import { HeaderSimple as Header } from '../common';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function PublicLayout({ children }: Props) {
    return (
        <>
            <Header />

            <Box component="main">
                <Stack
                    sx={{
                        py: 12,
                        m: 'auto',
                        maxWidth: 1200,
                        minHeight: '100vh'
                    }}
                >
                    {children}
                </Stack>
            </Box>
        </>
    );
}
