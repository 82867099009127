import { useMemo } from 'react';
import useSWR from 'swr';
// utils
import axios, { fetcher, endpoints } from 'utils/axios';
// types
import { ISetting } from 'types/mock';

// ----------------------------------------------------------------------

const URL = endpoints.setting;
const userSettingURL = endpoints.userSetting;

export function useGetSetting() {
    const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

    const memoizedValue = useMemo(
        () => ({
            setting: data
                ? (data as ISetting)
                : ({
                      id: '1',
                      services: [],
                      duration: 1,
                      weatherNotification: false,
                      updatedAt: new Date(),
                      createdAt: new Date()
                  } as ISetting),
            settingLoading: isLoading,
            settingError: error,
            settingValidating: isValidating,
            settingEmpty: !isLoading && (data ?? []).length === 0
        }),
        [data, error, isLoading, isValidating]
    );

    return memoizedValue;
}

// ----------------------------------------------------------------------

export async function getConfigure() {
    const resData = await axios.get(URL);
    return resData.data;
}

// ----------------------------------------------------------------------

export async function updateSetting(settingId: string, settingData: any) {
    /**
     * Work on server
     */
    const data = settingData;
    const resData = await axios.put(`${URL}/${settingId}`, data);
    return resData.data;
}

// ----------------------------------------------------------------------

export async function getUserSetting(userId: string) {
    /**
     * Work on server
     */
    const resData = await axios.get(`${userSettingURL}/${userId}`);
    return resData.data;
}

// ----------------------------------------------------------------------

export async function updateUserSetting(settingId: string, updateSettingData: any) {
    /**
     * Work on server
     */
    const resData = await axios.put(`${userSettingURL}/${settingId}`, updateSettingData);
    return resData.data;
}

// ----------------------------------------------------------------------

export async function sendWeatherAlarm() {
    /**
     * Work on server
     */
    const resData = await axios.post(`${URL}/weather`);
    return resData.data;
}
