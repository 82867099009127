import { lazy } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import BlankLayout from 'layouts/blank';

// ----------------------------------------------------------------------

const EstimatePDFPage = lazy(() => import('pages/view/estimate/pdf'));
const InvoicePDFPage = lazy(() => import('pages/view/invoice/pdf'));
const ReportPDFPage = lazy(() => import('pages/view/report/pdf'));

// ----------------------------------------------------------------------

export const blankRoutes = [
    {
        element: (
            <BlankLayout>
                <Outlet />
            </BlankLayout>
        ),
        children: [
            { path: 'estimate/:estimateId', element: <EstimatePDFPage /> },
            { path: 'invoice/:invoiceId', element: <InvoicePDFPage /> },
            { path: 'report/pdf/:reportId', element: <ReportPDFPage /> }
        ]
    }
];
