// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lightbox
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// editor
import 'react-quill/dist/quill.snow.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// image editor
import 'tui-image-editor/dist/tui-image-editor.css';
import 'tui-color-picker/dist/tui-color-picker.css';

// camera
import 'react-html5-camera-photo/build/css/index.css';

// ----------------------------------------------------------------------

// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// routes
import Router from 'routes/sections';
// theme
import ThemeProvider from 'theme';
// hooks
import { useScrollToTop } from 'hooks/use-scroll-to-top';
// components
import ProgressBar from 'components/progress-bar';
import MotionLazy from 'components/animate/motion-lazy';
import SnackbarProvider from 'components/snackbar/snackbar-provider';
import { SettingsProvider, SettingsDrawer } from 'components/settings';
// auth
import { AuthProvider, AuthConsumer } from 'auth/context';
// progress
import { ProgressConsumer, ProgressProvider } from 'progress/context';
// navigation
import { NavigationProvider } from 'navigation/context';
import { AppProvider } from 'app/context';

// ----------------------------------------------------------------------

export default function App() {
    const charAt = `

        ▒▓██▓▒ 
          ██
          ▒▒ rock Frontend - CMS

  `;
    console.clear();
    console.info(`%c${charAt}`, 'color: red;font-size:20px;font-weight:bold');
    console.info(
        `%c Warning! - Someone may steal your account info or something that is important by running a script. Don't run any script if you can't trust and understand how it works.`,
        'color: red;font-size:16px;font-weight:bold'
    );

    useScrollToTop();

    return (
        <AppProvider>
            <NavigationProvider>
                <AuthProvider>
                    <ProgressProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <SettingsProvider
                                defaultSettings={{
                                    SERVICE_OPTIONS: [],
                                    DURATION: 0,
                                    WEATHER_NOTIFICATION: false,
                                    themeMode: 'light', // 'light' | 'dark'
                                    themeDirection: 'ltr', //  'rtl' | 'ltr'
                                    themeContrast: 'default', // 'default' | 'bold'
                                    themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                                    themeColorPresets: 'red', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
                                    themeStretch: true
                                }}
                            >
                                <ThemeProvider>
                                    <MotionLazy>
                                        <SnackbarProvider>
                                            <SettingsDrawer />
                                            <ProgressBar />
                                            <ProgressConsumer />
                                            {/* <NavigationConsumer /> */}
                                            <AuthConsumer>
                                                <Router />
                                            </AuthConsumer>
                                        </SnackbarProvider>
                                    </MotionLazy>
                                </ThemeProvider>
                            </SettingsProvider>
                        </LocalizationProvider>
                    </ProgressProvider>
                </AuthProvider>
            </NavigationProvider>
        </AppProvider>
    );
}
