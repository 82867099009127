import { useMemo } from 'react';
// routes
import { paths } from 'routes/paths';
// components
import SvgColor from 'components/svg-color';

// ----------------------------------------------------------------------

const icon = (path: string) => <SvgColor src={path} sx={{ width: 1, height: 1 }} />;

const navIcon = (name: string) => icon(`/assets/icons/navbar/${name}.svg`);

export const NAV_ICONS = {
    sale: navIcon('ic_sale'),
    customer: navIcon('ic_customer'),
    estimate: navIcon('ic_estimate'),
    invoice: navIcon('ic_invoice'),
    accounting: navIcon('ic_accounting'),
    project: navIcon('ic_project'),
    job: navIcon('ic_job'),
    map: navIcon('ic_map'),
    todo: navIcon('ic_todo'),
    subcontractor: navIcon('ic_subcontractor'),
    insurance: navIcon('ic_insurance'),
    estate: navIcon('ic_estate'),

    blog: navIcon('ic_blog'),
    chat: navIcon('ic_chat'),
    mail: navIcon('ic_mail'),
    user: navIcon('ic_user'),
    file: navIcon('ic_file'),
    lock: navIcon('ic_lock'),
    tour: navIcon('ic_tour'),
    order: navIcon('ic_order'),
    label: navIcon('ic_label'),
    blank: navIcon('ic_blank'),
    kanban: navIcon('ic_kanban'),
    folder: navIcon('ic_folder'),
    banking: navIcon('ic_banking'),
    booking: navIcon('ic_booking'),
    product: navIcon('ic_product'),
    calendar: navIcon('ic_calendar'),
    disabled: navIcon('ic_disabled'),
    external: navIcon('ic_external'),
    menuItem: navIcon('ic_menu_item'),
    ecommerce: navIcon('ic_ecommerce'),
    analytics: navIcon('ic_analytics'),
    dashboard: navIcon('ic_dashboard'),
    photo: navIcon('ic_photo'),
    report: navIcon('ic_report'),
    setting: navIcon('ic_setting')
};

// ----------------------------------------------------------------------

export function useNavData() {
    const data = useMemo(
        () => [
            // GENERAL
            // ----------------------------------------------------------------------
            {
                subheader: 'general',
                items: [
                    { title: 'dashboard', path: paths.dashboard.general.app, icon: NAV_ICONS.dashboard },
                    { title: 'sales', path: paths.dashboard.general.sale, icon: NAV_ICONS.sale }
                ]
            },

            // MANAGEMENT
            // ----------------------------------------------------------------------
            {
                subheader: 'management',
                items: [
                    // USER
                    {
                        title: 'user',
                        path: paths.dashboard.user.list,
                        icon: NAV_ICONS.user,
                        roles: ['admin', 'accounting', 'sales_manager']
                    },
                    // CUSTOMER
                    {
                        title: 'customer',
                        path: paths.dashboard.customer.list,
                        icon: NAV_ICONS.customer,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    // SUBCONTRACTOR
                    {
                        title: 'subcontractor',
                        path: paths.dashboard.subcontractor.list,
                        icon: NAV_ICONS.subcontractor,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },

                    // INSURANCE AGENT
                    {
                        title: 'insurance agent',
                        path: paths.dashboard.insurance.list,
                        icon: NAV_ICONS.insurance,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },

                    // REAL ESTATE
                    {
                        title: 'real estate',
                        path: paths.dashboard.realEstate.list,
                        icon: NAV_ICONS.estate,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    }
                ]
            },
            // PAYMENT
            // ----------------------------------------------------------------------
            {
                subheader: 'payment',
                items: [
                    // ESTIMATE
                    {
                        title: 'estimate',
                        path: paths.dashboard.estimate.list,
                        icon: NAV_ICONS.estimate,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    // INVOICE
                    {
                        title: 'invoice',
                        path: paths.dashboard.invoice.list,
                        icon: NAV_ICONS.invoice,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    // ACCOUNTING
                    {
                        title: 'accounting',
                        path: paths.dashboard.accounting.list,
                        icon: NAV_ICONS.accounting,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    }
                ]
            },
            // APP
            // ----------------------------------------------------------------------
            {
                subheader: 'app',
                items: [
                    // CHAT
                    {
                        title: 'communication',
                        path: paths.dashboard.chat,
                        icon: NAV_ICONS.chat,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    {
                        title: 'map',
                        path: paths.dashboard.map,
                        icon: NAV_ICONS.map,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    {
                        title: 'todo',
                        path: paths.dashboard.todo,
                        icon: NAV_ICONS.todo,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    // TASK
                    {
                        title: 'project',
                        path: paths.dashboard.project.list,
                        icon: NAV_ICONS.project,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    // TASK
                    {
                        title: 'job',
                        path: paths.dashboard.job.list,
                        icon: NAV_ICONS.job,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    {
                        title: 'File manager',
                        path: paths.dashboard.fileManager,
                        icon: NAV_ICONS.folder,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    {
                        title: 'Photo',
                        path: paths.dashboard.photo,
                        icon: NAV_ICONS.photo,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    },
                    {
                        title: 'Report',
                        path: paths.dashboard.report.list,
                        icon: NAV_ICONS.report,
                        roles: ['admin', 'administrator_assistant', 'accounting', 'project_manager', 'sales_manager']
                    }
                ]
            }
        ],
        []
    );

    return data;
}
