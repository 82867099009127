// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function BlankLayout({ children }: Props) {
    return (
        <Box component="main">
            <Stack
                sx={{
                    py: 12,
                    m: 'auto',
                    maxWidth: 1200,
                    minHeight: '100vh',
                    justifyContent: 'center'
                }}
            >
                {children}
            </Stack>
        </Box>
    );
}
